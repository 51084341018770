html {
  height: 100%
}

body {
  min-height: 100%;
  position: relative
}

body.landing {
  min-height: 500px;
  height: 100%;
  padding: 0;
  margin: 0
}

#home-cover {
  width: 100%;
  background-position: center center;
  background-color: #333;
  background-size: cover;
  color: #fff;
  position: relative
}

body.landing #home-cover {
  margin: 0;
  padding: 0;
  height: 100%;
  min-height: 500px
}

#home-cover-content {
  position: relative;
  text-align: center
}

/*
body.landing #home-cover-content {
  top: 50%;
  position: relative;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  overflow: auto
}
*/

.center-box {
  margin-right: auto !important;
  margin-left: auto !important
}

#home-cover-slideshow {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden
}

.home-cover-img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  display: block;
  opacity: 0;
  -webkit-transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden
}

.home-cover-img.animate-in,
.home-cover-img.animate-in--alt {
  -webkit-animation-name: slideshow;
  animation-name: slideshow;
  -webkit-animation-duration: 10s;
  animation-duration: 10s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards
}

.home-cover-img.animate-in--alt {
  opacity: 1;
  -webkit-animation-name: slideshow--alt;
  animation-name: slideshow--alt
}

.home-cover-img.animate-end {
  opacity: 1;
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  -webkit-animation: none;
  animation: none
}

@-webkit-keyframes slideshow {
  0% {
    opacity: 0
  }

  20% {
    opacity: 1
  }

  100% {
    opacity: 1;
    -webkit-transform: scale(1.1);
    transform: scale(1.1)
  }
}

@keyframes slideshow {
  0% {
    opacity: 0
  }

  20% {
    opacity: 1
  }

  100% {
    opacity: 1;
    -webkit-transform: scale(1.1);
    transform: scale(1.1)
  }
}

@-webkit-keyframes slideshow--alt {
  100% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1)
  }
}

@keyframes slideshow--alt {
  100% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1)
  }
}

@supports (-ms-accelerator:true) {
  .home-cover-img.animate-end {
    -webkit-transform: scale(1);
    transform: scale(1)
  }

  @-webkit-keyframes slideshow {
    0% {
      opacity: 0
    }

    20% {
      opacity: 1
    }
  }

  @keyframes slideshow {
    0% {
      opacity: 0
    }

    20% {
      opacity: 1
    }
  }

  @-webkit-keyframes slideshow--alt {
    100% {
      -webkit-transform: scale(1);
      transform: scale(1)
    }
  }

  @keyframes slideshow--alt {
    100% {
      -webkit-transform: scale(1);
      transform: scale(1)
    }
  }
}

/*
.section-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6)
}
*/

/*
.title,
.subtitle,
.subtitle strong {
  text-shadow: 1px 1px 5px #000;
  color: #fff
}

#home-cover-content .main-column {
  border-radius: 4px;
  background-color: #000c
}
*/

#home-cover-slideshow::after {
  content: "";
  z-index: 1;
  height: 50px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.1)), to(rgba(0, 0, 0, 0)));
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0) 100%);
  background: -o-linear-gradient(top, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0) 100%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0) 100%)
}

.hero.is-fullheight > .hero-body > .container {
  /*max-width: 400px;*/
  padding: 40px 10px;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 0.5rem
}

/*@media (min-width: 769px) {
  .columns.is-gapless > .column.is-hidden-mobile {
    max-width: 50px
  }
}*/